.Contact {
  font-size: 1.3rem;
  max-width: 600px !important;
}

.Contact ul {
  list-style-type: none;
  margin-top: 5rem;
}

.Contact li > a {
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Contact a {
  text-decoration: none;
  color: black;
}

.Contact a:hover {
  text-decoration: none;
  color: rgb(42, 42, 42);
}
