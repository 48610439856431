@import url('https://fonts.googleapis.com/css2?family=Swanky+and+Moo+Moo&display=swap');

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App > :first-child {
  width: 100%;
}

.App > :nth-child(2) {
  flex-grow: 1;
  width: 80%;
}
