.Management {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80vw;
  margin: auto;
}

.Management > :nth-child(1) {
  min-width: 200px;
  width: 20%;
}

.Management > :nth-child(2) {
  flex-grow: 1;
}
