:global(.pro-gallery) {
  overflow-y: hidden;
  margin-top: -2rem;
}

.Hover {
  color: aliceblue;
  position: absolute;
  bottom: 40px;
  right: 40px;
  left: 40px;
}

.Hover h3 {
  font-style: italic;
}
