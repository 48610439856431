.Avatar {
  max-width: 4rem;
  border-radius: 0.6rem;
  float: left;
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.Email {
  font-style: italic;
}

.Toggle {
  margin-bottom: 1rem;
}

.Toggle > label {
  display: flex;
  align-items: center;
  max-width: 200px;
}

.Toggle > label > span {
  flex-grow: 1;
}

.Buttons {
  display: flex;
  justify-content: space-between;
}

.Button {
  font-family: 'Swanky and Moo Moo', cursive;
  border: 0;
  background-color: #0000;
  outline: black;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}
