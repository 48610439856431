.ImageUpload {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
}

.ImageUpload:hover {
  background-color: #e0f0ff;
}

.ImageUpload img {
  max-height: 250px;
}

.ImageUpload .Name {
  margin-left: 2rem;
  flex-grow: 1;
}

.ImageUpload .Size {
  margin-right: 2rem;
}
