.Header {
  text-align: center;
}

.Header > div > h1 {
  font-family: 'Swanky and Moo Moo', cursive;
  font-size: 3.25em;
  font-weight: lighter;
  flex-grow: 1;
  text-align: center;
  margin: 1rem 0;
  color: #ff6f6f;
}

.Header a,
.Header a:visited {
  color: black;
  text-decoration: none;
}

.Header a:hover {
  color: #555;
}

.Header > div {
  display: flex;
}

.Header > div > button {
  display: none;
  background-color: aliceblue;
  border: 0;
  font-size: 1.4em;
  margin-right: 1.25rem;
  padding-top: 0.5rem;
}

.Header img {
  max-height: 1.2rem;
  border-radius: 0.6rem;
}

.Header > ul {
  width: 80%;
  margin: 0 auto 1em auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  font-size: 2em;
  font-family: 'Swanky and Moo Moo', cursive;
}

.Header > ul > li {
  margin-right: 1rem;
}

.Header > ul > li:last-child {
  margin: 0;
  /* flex-grow: 1; */
}

.Header > ul > li > * {
  margin-right: 0.5rem;
}

.Header > ul > li > *:last-child {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .Header > ul {
    width: 90vw;
  }
}

@media screen and (max-width: 600px) {
  .Header > div > button {
    display: block;
  }

  .Header > ul {
    display: none;
    width: 100vw;
  }

  .Header > :global(.open) {
    display: block;
  }
}
