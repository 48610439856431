.About {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.About img {
  max-width: 500px;
  max-height: 500px;
  width: 80%;  
}
