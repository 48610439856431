
@media screen and (min-width: 900px) {
  .Image {
    display: flex;
    flex-direction: row;
  }

  .Image .ImageDisplay {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }

  .Image .ImageDetails {
    width: 500px;
    padding: 100px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 900px) {
  .Image {
    flex-direction: column !important;
  }

  .Image .ImageDisplay {
    display: flex;
    flex-direction: column;
  }

  .Image .ImageDetails {
    font-size: 1.3rem;
  }
}
