@import url(https://fonts.googleapis.com/css2?family=Swanky+and+Moo+Moo&display=swap);
body {
  margin: 0;
  background-color: aliceblue;
  font-family: 'Swanky and Moo Moo', cursive;
}

.App_App__2Q0Uf {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App_App__2Q0Uf > :first-child {
  width: 100%;
}

.App_App__2Q0Uf > :nth-child(2) {
  flex-grow: 1;
  width: 80%;
}

.pro-gallery {
  overflow-y: hidden;
  margin-top: -2rem;
}

.Gallery_Hover__1Q1So {
  color: aliceblue;
  position: absolute;
  bottom: 40px;
  right: 40px;
  left: 40px;
}

.Gallery_Hover__1Q1So h3 {
  font-style: italic;
}

.Header_Header__2muy8 {
  text-align: center;
}

.Header_Header__2muy8 > div > h1 {
  font-family: 'Swanky and Moo Moo', cursive;
  font-size: 3.25em;
  font-weight: lighter;
  flex-grow: 1;
  text-align: center;
  margin: 1rem 0;
  color: #ff6f6f;
}

.Header_Header__2muy8 a,
.Header_Header__2muy8 a:visited {
  color: black;
  text-decoration: none;
}

.Header_Header__2muy8 a:hover {
  color: #555;
}

.Header_Header__2muy8 > div {
  display: flex;
}

.Header_Header__2muy8 > div > button {
  display: none;
  background-color: aliceblue;
  border: 0;
  font-size: 1.4em;
  margin-right: 1.25rem;
  padding-top: 0.5rem;
}

.Header_Header__2muy8 img {
  max-height: 1.2rem;
  border-radius: 0.6rem;
}

.Header_Header__2muy8 > ul {
  width: 80%;
  margin: 0 auto 1em auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  font-size: 2em;
  font-family: 'Swanky and Moo Moo', cursive;
}

.Header_Header__2muy8 > ul > li {
  margin-right: 1rem;
}

.Header_Header__2muy8 > ul > li:last-child {
  margin: 0;
  /* flex-grow: 1; */
}

.Header_Header__2muy8 > ul > li > * {
  margin-right: 0.5rem;
}

.Header_Header__2muy8 > ul > li > *:last-child {
  margin: 0;
}

@media screen and (max-width: 900px) {
  .Header_Header__2muy8 > ul {
    width: 90vw;
  }
}

@media screen and (max-width: 600px) {
  .Header_Header__2muy8 > div > button {
    display: block;
  }

  .Header_Header__2muy8 > ul {
    display: none;
    width: 100vw;
  }

  .Header_Header__2muy8 > .open {
    display: block;
  }
}

.About_About__1g6oP {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About_About__1g6oP p {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.About_About__1g6oP img {
  max-width: 500px;
  max-height: 500px;
  width: 80%;  
}

.ImageUpload_ImageUpload__3DRQS {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
}

.ImageUpload_ImageUpload__3DRQS:hover {
  background-color: #e0f0ff;
}

.ImageUpload_ImageUpload__3DRQS img {
  max-height: 250px;
}

.ImageUpload_ImageUpload__3DRQS .ImageUpload_Name__3dJj- {
  margin-left: 2rem;
  flex-grow: 1;
}

.ImageUpload_ImageUpload__3DRQS .ImageUpload_Size__3Eo2x {
  margin-right: 2rem;
}

.User_Avatar__QaNea {
  max-width: 4rem;
  border-radius: 0.6rem;
  float: left;
  margin-top: 1rem;
  margin-right: 0.5rem;
}

.User_Email__1jkYg {
  font-style: italic;
}

.User_Toggle__1Qeut {
  margin-bottom: 1rem;
}

.User_Toggle__1Qeut > label {
  display: flex;
  align-items: center;
  max-width: 200px;
}

.User_Toggle__1Qeut > label > span {
  flex-grow: 1;
}

.User_Buttons__1t7Bw {
  display: flex;
  justify-content: space-between;
}

.User_Button__3w1Oc {
  font-family: 'Swanky and Moo Moo', cursive;
  border: 0;
  background-color: #0000;
  outline: black;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.Users_Avatar__3dvlZ {
  max-height: 1.2rem;
  border-radius: 0.6rem;
}

.Management_Management__3Zz7L {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 80vw;
  margin: auto;
}

.Management_Management__3Zz7L > :nth-child(1) {
  min-width: 200px;
  width: 20%;
}

.Management_Management__3Zz7L > :nth-child(2) {
  flex-grow: 1;
}

.Contact_Contact__2Frdg {
  font-size: 1.3rem;
  max-width: 600px !important;
}

.Contact_Contact__2Frdg ul {
  list-style-type: none;
  margin-top: 5rem;
}

.Contact_Contact__2Frdg li > a {
  font-size: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Contact_Contact__2Frdg a {
  text-decoration: none;
  color: black;
}

.Contact_Contact__2Frdg a:hover {
  text-decoration: none;
  color: rgb(42, 42, 42);
}


@media screen and (min-width: 900px) {
  .Image_Image__1JyDn {
    display: flex;
    flex-direction: row;
  }

  .Image_Image__1JyDn .Image_ImageDisplay__fJ4kI {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
  }

  .Image_Image__1JyDn .Image_ImageDetails__3SdAn {
    width: 500px;
    padding: 100px;
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 900px) {
  .Image_Image__1JyDn {
    flex-direction: column !important;
  }

  .Image_Image__1JyDn .Image_ImageDisplay__fJ4kI {
    display: flex;
    flex-direction: column;
  }

  .Image_Image__1JyDn .Image_ImageDetails__3SdAn {
    font-size: 1.3rem;
  }
}

